<template>
  <div class="settings">


    <div>
      <div v-if="pageLoaded">
        <div style="padding: 30px; margin-top: -30px; margin-right: 12px; padding-bottom: 0; float: right;">
            <button @click="routeBack('/panel/ilanlar/', false)" class="editButton" style="width: 80px; ">
              <img style="height: 8px; margin-right: 5px; " src="https://gcdn.bionluk.com/site/icon/aws/ic_chevron_left_white.svg">
              Geri Dön
            </button>
        </div>

        <p class="page-header">
          İş ilanı Düzenleme
        </p>

        <div style="font-size: 15px; text-align: center; padding: 10px 20px; margin-top: 60px;">

          İlan düzenleme özelliği <span style="color: #2d3640; font-weight: 500;">mobil uygulamalarda</span> şu an için aktif değildir. Lütfen Bionluk.com'a masaüstü bilgisayarından bağlan.

        </div>



        <div class="hiddendiv"></div> <div class="hiddendiv"></div> <div class="hiddendiv"></div>
      </div>
      <loader v-else></loader>
    </div>
  </div>
</template>

<script>

  import WorkstationNav from "../../navComponent";

  export default {
    name: "src-pages-body-workstation-gigs-edit-v1_mobile",
    components: {
      WorkstationNav
    },

    data () {
      return {
        selectedCategory: null,
        selectedSubCategory: null,
        selectedPriceType: 1,
        completionPeriod: 1,
        pageLoaded: null,
        priceTypes: null,
        pricingDisplayType: 1,
        description: null,
        title: null,
        price: 10,
        maxPrice: 10,
        count: null,
        showPriceType: null,
        foundCategory: {
          info: {
            minPrice: 10
          }
        }
      }
    },

    methods: {
      createGig() {
        let gigInfo = {
          category_id: this.selectedCategory,
          category_sub_id: this.selectedSubCategory,
          title: this.title,
          description: this.description,
          gig_price_type_count: this.count,
          gig_price_type_id: this.selectedPriceType,
          price: this.price,
          duration: this.completionPeriod,
          gig_pricing_display_type: this.pricingDisplayType
        };

        if (this.pricingDisplayType === 3) {
          gigInfo.max_price = this.maxPrice;
        }

        this.api.seller.createGig(gigInfo, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$router.push(`/panel/ilanlar/duzenle/${result.data.uuid}`);
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    computed: {
      charCount() {
        if (this.description) {
          return this.description.length;
        } else {
          return 0;
        }
      },

      checkToDisable() {
        return !this.title || !this.price || !this.completionPeriod  || !this.selectedCategory  || !this.selectedSubCategory
      }
    },

    watch: {
      selectedCategory(newVal, oldVal) {
        this.selectedSubCategory = null;
      },

      async selectedSubCategory(newVal) {
        if (newVal) {
          this.foundCategory = await this.findCategoryByID(newVal)
          if (this.foundCategory && this.foundCategory.info) {
            this.showPriceType = !!this.foundCategory.info.initialPriceTypeId;
            this.selectedPriceType = this.foundCategory.info.initialPriceTypeId ? this.foundCategory.info.initialPriceTypeId : null;
            this.count = this.foundCategory.info.initialPriceTypeCount ? this.foundCategory.info.initialPriceTypeCount : null;
            this.price = this.foundCategory.info.minPrice ? this.foundCategory.info.minPrice : null;
            this.maxPrice = this.price;
          }
        } else {
          this.showPriceType = null;
          this.selectedPriceType = null;
          this.count = null;
          this.price = null;
          this.maxPrice = null;
        }
      },

      price(newVal, oldVal) {
        if (newVal && this.maxPrice < this.price) {
          this.maxPrice = this.price;
        }
      }
    },

    created() {
      this.api.seller.getGigPriceTypes()
        .then(({data}) => {
          let result = data;
          this.pageLoaded = true;

          if (result.success) {
            this.priceTypes = result.data.gig_price_types;
          } else {
            this.$router.push(result.redirect_url);
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    }
  }
</script>

<style scoped>
  .chatrow {
    height: 80px; background-color: #ffffff;  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }



</style>
